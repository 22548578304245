import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Side = () => {
	// popup
	const [flg, setFlg] = useState(false);
	const side = () => {
		if (flg) {
			setFlg(false);
		} else {
			setFlg(true);
		}
	};

	useEffect(() => {}, []);

	return (
		<>
			<div className="l-contents__side">
				<aside className={flg ? 'l-sidebar is-open' : 'l-sidebar'}>
					<div className="l-sidebar__inner">
						<ul className="l-sidebar__menu">
							<li className="c-menuBtn">
								<Link to="/" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
										<img src="/assets/images/icon_home_black01.svg" alt="" />
										<img src="/assets/images/icon_home_blue01.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle">ホーム</div>
								</Link>
							</li>
							<li className="c-menuBtn">
								<Link to="/scheduled" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
										<img
											src="/assets/images/icon_analysis_black01.svg"
											alt=""
										/>
										<img src="/assets/images/icon_analysis_blue01.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle">
										定期分析一覧
									</div>
								</Link>
							</li>
							<li className="c-menuBtn">
								<Link to="/questionnaire" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon u-ml5 u-w30 u-h30">
										<img src="/assets/images/icon_survey_black01.svg" alt="" />
										<img src="/assets/images/icon_survey_blue01.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle u-ml15">
										調査一覧
									</div>
								</Link>
							</li>
							<li className="c-menuBtn">
								<Link to="/storage" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon u-ml5 u-w30 u-h30">
										<img src="/assets/images/folder-solid-black.svg" alt="" />
										<img src="/assets/images/folder-solid-blue.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle u-ml15">
										ストレージ
									</div>
								</Link>
							</li>
							<li className="c-menuBtn">
								<Link to="/search" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon u-ml5 u-w30 u-h30">
										<img src="/assets/images/icon_search01.svg" alt="" />
										<img src="/assets/images/icon_search01_blue.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle u-ml15">
										検索
									</div>
								</Link>
							</li>
						</ul>

						<ul className="l-sidebar__menu">
							<li className="c-menuBtn">
								<Link to="#" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
										<img src="/assets/images/icon_setting_black01.svg" alt="" />
										<img src="/assets/images/icon_setting_blue01.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle">設定</div>
								</Link>
							</li>
							<li className="c-menuBtn">
								<Link to="#" className="c-menuBtn__inner">
									<span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
										<img src="/assets/images/icon_help_black01.svg" alt="" />
										<img src="/assets/images/icon_help_blue01.svg" alt="" />
									</span>
									<div className="c-menuBtn__title side__menuTitle">ヘルプ</div>
								</Link>
							</li>
						</ul>

						<div className="l-sidebar__open">
							<Link to="#" className="js-sidebarOpen" onClick={() => side(flg)}>
								<span className="c-roundIcon c-roundIcon--color-white c-roundIcon--hov-bgGray">
									<span className="c-roundIcon__arrow c-roundIcon__arrow--right"></span>
								</span>
							</Link>
						</div>
					</div>
				</aside>
			</div>
		</>
	);
};

export default Side;
