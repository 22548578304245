import React, { Suspense, createContext } from "react";
import Sugar from "sugar";
import "sugar/locales/ja";
import './App.css';
import axios from "axios";
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import routes from './config/routes'

// Common
import Layout from "./includes/Layout";
export const ApiContext = createContext();
const Login = React.lazy(() => import('./pages/Login'));

Sugar.extend();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            me: {},
            // API
            host: '/api',
            user: '/users',
            auth: '/users/auth',
            announcements_list_api: '/announcements',
            analysis_list_api: '/results',
            questionnaire_list_api: '/questionnaires',
            scheduled_list_api: '/analysis/periodic',
            oneshot_api: '/results/oneshot/latest',
            labelings_api: '/labelings',
            users_api: '/me',
            storage_list_api: '/storages',
            hashtag_list_api: '/hashtags',
            entity_list_api: '/entities',
            axiosStatusCode: 200,

            // REST API用
            // host: 'https://cmscms.microcms.io/api/v1',
            // announcements_list_api: '/announcements',
            // analysis_list_api: '/results',
            // questionnaire_list_api: '/questionnaire',
            // scheduled_list_api: '/scheduled',
            // API_KEY: 'a781fee1-c5b8-4303-ab9c-7956eab9bda8',
            // WRITE_API_KEY: '59280e23-22a8-4a75-9056-24f0eff049e3',
        };

    }

    componentDidMount() {
        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                console.log(error)
                if (error.response.status === 401) {
                    if(localStorage.getItem('jfc')) {
                        let f = document.createElement('form');
                        f.method = 'post';
                        f.action = '/users/auth/login';
                        f.innerHTML = '<input name="email" value='+  localStorage.getItem('jfe') + '>' +
                            '<input name="password" value=' + localStorage.getItem('jfp') + '>';
                        document.body.append(f);
                        f.submit();

                        return
                    }

                    console.log(error.response.status)
                    this.setState({
                        axiosStatusCode : error.response.status,
                    })
                }
                return Promise.reject(error);
            }
        )

        // console.log('routes: ', routes);
        // console.log(this.state.isAuth)
    }


    render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <ApiContext.Provider value={{...this.state}}>
                    <BrowserRouter>
                        {this.state.axiosStatusCode === 401 ?
                            <>
                                <Route exact path={'/login'} component={Login} />
                                <Redirect from="/" to='/login' />
                            </>
                            :
                            <Layout>
                                <Switch>
                                    {routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={props => (<route.component {...props} />)}
                                        />
                                    ))}
                                    <Redirect from="/login" to='/' />
                                    <Redirect from="" to='/' />
                                </Switch>
                            </Layout>
                        }
                    </BrowserRouter>
                </ApiContext.Provider>
            </Suspense>
        );
    }
}

export default App;
