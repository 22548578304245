import React from 'react';

const Main = React.lazy(() => import('../pages/Main'));
const Home = React.lazy(() => import('../pages/Home'));
const NewsDetail = React.lazy(() => import('../pages/news/NewsDetail'));
const AnalysisSearchList = React.lazy(() => import('../pages/analysis/AnalysisSearchList'));
const AnalysisDetail = React.lazy(() =>
	import('../pages/analysis/AnalysisDetail')
);
const AnalysisDetailParts = React.lazy(() =>
	import('../pages/analysis/AnalysisDetailParts')
);
const QuestionnaireList = React.lazy(() =>
	import('../pages/questionnaire/QuestionnaireList')
);
const QuestionnaireResult = React.lazy(() =>
	import('../pages/questionnaire/QuestionnaireResult')
);
const QuestionnaireMake = React.lazy(() =>
	import('../pages/questionnaire/QuestionnaireMake')
);
const ScheduledList = React.lazy(() =>
	import('../pages/scheduled/ScheduledList')
);
const ScheduledListTimeSeries = React.lazy(() =>
	import('../pages/scheduled/ScheduledListTimeSeries')
);
// const NotFound = React.lazy(() => import('../pages/404'));
const StorageList = React.lazy(() =>
	import('../pages/storage/StorageList')
);

const SearchList = React.lazy(() =>
	import('../pages/search/SearchList')
);

const routes = [
	{ path: '/', exact: true, name: 'Main', component: Home },
	{
		path: '/news/detail/:id',
		exact: true,
		name: 'NewsDetail',
		component: NewsDetail,
	},
	{
		path: '/analysis',
		exact: true,
		name: 'AnalysisList',
		component: AnalysisSearchList,
	},
	{
		path: '/analysis/detail/:id',
		exact: true,
		name: 'AnalysisDetail',
		component: AnalysisDetail,
	},
	{
		path: '/analysis/detail/parts/:id',
		exact: true,
		name: 'AnalysisDetailParts',
		component: AnalysisDetailParts,
	},
	{
		path: '/questionnaire',
		exact: true,
		name: 'QuestionnaireList',
		component: QuestionnaireList,
	},
	{
		path: '/questionnaire/result/:id',
		exact: true,
		name: 'QuestionnaireResult',
		component: QuestionnaireResult,
	},
	{
		path: '/questionnaire/make/:id',
		exact: true,
		name: 'QuestionnaireMake',
		component: QuestionnaireMake,
	},
	// { path: '/questionnaire/make/confirm/:id', exact: true, name: 'QuestionnaireMakeConfirm', component: QuestionnaireMakeConfirm },
	// { path: '/questionnaire/make/complete', exact: true, name: 'QuestionnaireMakeComplete', component: QuestionnaireMakeComplete },
	{
		path: '/scheduled',
		exact: true,
		name: 'ScheduledList',
		component: ScheduledList,
	},
	{
		path: '/scheduled/timeseries/:id',
		exact: true,
		name: 'ScheduledListTimeSeries',
		component: ScheduledListTimeSeries,
	},
	// { path: '', exact: false, name: 'NotFound', component: NotFound },
	{
		path: '/storage',
		exact: true,
		name: 'StorageList',
		component: StorageList,
	},
	{
		path: '/search',
		exact: true,
		name: 'SearchList',
		component: SearchList,
	},
];

export default routes;
