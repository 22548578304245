import React, { useState, useEffect, useContext } from 'react';
import {
  useHistory,
} from 'react-router-dom';
import axios from "axios";
import Sugar from 'sugar';
import { ApiContext } from "@/App";

// DatePicker
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ja', ja);

const PopupExecuteAnalysis = (props) => {
  const api = useContext(ApiContext);
  const url = api.host + api.analysis_list_api;

  const [targetWord, setTargetWord] = useState("");
  const [hashtagString, setHashtagString] = useState("");
  const [itemCount, setItemCount] = useState();
  const [isManualLabeling, setIsManualLabeling] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const history = useHistory();

  const sendData = async () => {
    if (isSending) return;
    setIsSending(true);
    const res = await axios.post(url, {
      target_words: targetWord,
      except_words: "",
      count: itemCount,
      is_manual_labeling: isManualLabeling,
    });
    const id = res.data.data.id;

    const hashtags = hashtagString.split(/\s/g).map(x => x.replace(/#/g, ""));

    if (hashtags.length > 0)
    {
      await axios.put(`${url}/${id}/hashtags`, {
        hashtags: hashtags,
      });
    }

    props.modalCloseCallback();
    setIsSending(false);

    history.push({
      pathname: "/",
    });
  };


  useEffect(() => {
    setItemCount(100);
  }, []);

  return (
    <div
      className={props.openname === 'exec-analysis' ? 'p-pop is-open' : 'p-pop'}
      data-name="pop6"
    >
      <div
        className="p-pop__overlay js-popClose"
        onClick={() => props.modalCloseCallback()}
      />
      <div className="p-pop__inner">
        <div
          className="p-pop__closeBtn js-popClose"
          onClick={() => props.modalCloseCallback()}
        />
        <div className="p-pop__cont">
          <h2 className="c-popHeading">分析を新規作成</h2>

          <form onSubmit={(e) => {}}>
            <div className="c-conditionList">
              <div className="c-conditionList__inner c-conditionList__inner--size-s">
                <dl className="c-conditionList__list">
                  <dd className="c-conditionList__cont">
										<span className="c-inputForm">
											<input
                        type="text"
                        name="target_word"
                        placeholder="分析したいキーワードを入力"
                        onChange={(e) => { setTargetWord(e.target.value) }}
                      />
										</span>
                  </dd>
                  <dt className="c-conditionList__title">このキーワードの関連タグ</dt>
                  <dd className="c-conditionList__cont">
										<span className="c-inputForm">
											<input
                        type="text"
                        name="hashtags"
                        placeholder="#"
                        onChange={(e) => { setHashtagString(e.target.value) }}
                      />
										</span>
                  </dd>
                  <dt className="c-conditionList__title">抽出する件数</dt>
                  <dd className="c-conditionList__cont">
                    <span className="c-inputForm c-inputForm--size-s">
                      <select className="u-w200" value={itemCount} onChange={(e) => {setItemCount(parseInt(e.target.value, 10))}}>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                        <option value={5000}>5000</option>
                        <option value={10000}>10000</option>
                      </select>
										</span>
                  </dd>
                  <dd className="c-conditionList__cont">
                    <label style={{ cursor: "pointer" }}>
                      <input type="checkbox" name="is_manual_labeling" onClick={(e) => setIsManualLabeling(e.target.checked)} />&nbsp;&nbsp;抽出したトピックをラベリングする
                    </label>
                  </dd>
                </dl>
                <div className="c-conditionList__btn c-btn">
                  <input
                    type="button"
                    className={`c-btn__link c-btn__link--size-l js-popClose ${targetWord.length === 0 ? 'u-bgLightGray' : ''}`}
                    value="このキーワードで分析開始"
                    disabled={targetWord.length === 0 || isSending}
                    onClick={() => sendData()}
                    style={{ cursor: targetWord.length > 0 ? 'pointer' : 'default' }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupExecuteAnalysis;
