import SideMemoTextarea from "./SideMemoTextarea";
import React, {useContext, useEffect, useState} from "react";
import toastr from "toastr";
import Sugar from "sugar";
import axios from "axios";
import {ApiContext} from "../App";
import MemoCard from "./MemoCard";
import InfiniteScroll  from "react-infinite-scroller"
import SyncLoader from "react-spinners/SyncLoader";

const SideMemo = (props) => {
  const api = useContext(ApiContext);

  const [memo, setMemo] = useState([""]);
  const [memoHistory, setMemoHistory] = useState([]);
  const [pinnedMemoHistory, setPinnedMemoHistory] = useState([]);
  const [normalMemoHistory, setNormalMemoHistory] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isWide, setIsWide] = useState(false);
  const [memoId, setMemoId] = useState(null);
  const [pinnedMaxPage, setPinnedMaxPage] = useState(null);

  useEffect(() => {
    const memoString  = localStorage.getItem("jfmemo") || "";
    try {
      const json = JSON.parse(memoString);
      setMemo(json);
    } catch (e) {
      setMemo([memoString]);
    }

    props.handleChangeMemo((markdown) => {
      if (memo[memo.length - 1].length == 0)
      {
        memo[memo.length - 1] = markdown;
      }
      else
      {
        memo.push(markdown);
      }
      memo.push("");
      setMemo(memo.concat());
    });
  }, []);

  const handleMemoChange = (e) => {
    setMemo(e);
    localStorage.setItem("jfmemo", JSON.stringify(e));
  };

  const handleMemoSave = async () => {
    toastr.info("テキストメモを保存中...");
    const dataString = memo.join("\n");
    const blob = new Blob([dataString], {type: "text/plain; charset=utf-8"});
    const timeString = Sugar.Date.format(new Date(), "%Y%m%d%H%M%S");
    const fileName = `${timeString}_テキストメモ.txt`;
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("file_type_id", 2);

    let hashTags = [];
    for (const hashTag of dataString.matchAll(/\s*#([^\s#]+)\s*/g)) {
      hashTags.push(hashTag[1]);
    }
    hashTags = hashTags.unique();
    if (hashTags.length > 0)
    {
      for (const hashTag of hashTags) {
        formData.append("hashtags[]", hashTag);
      }
    }
    if (memoId != null)
    {
      let item = memoHistory.find({id: memoId});
      if (item) {
        for (const hashTag of item.hashtags) {
          if (hashTag[0] == "!")
          {
            formData.append("hashtags[]", hashTag);
          }
        }
      }
    }

    const url = api.host + api.storage_list_api;
    if (memoId == null)
    {
      const result = await axios.post(url, formData);
      console.log(result);
    }
    else
    {
      const result = await axios.post(`${url}/${memoId}`, formData, {
        headers: {
          'X-HTTP-Method-Override': 'PUT'
        },
      });
      console.log(result);
    }
    await retrieveMemo(1);

    if (memoId == null)
    {
      toastr.success("テキストメモを保存しました");
    }
    else
    {
      toastr.success("テキストメモを更新しました");
    }

    setMemo([""]);
    setMemoId(null);
    localStorage.setItem("jfmemo", "");
  };
  const handleCancel = () => {
    setMemo([""]);
    setMemoId(null);
  };

  const handleExpand = () => {
    setIsWide(true);
  };
  const handleCollapse = () => {
    setIsWide(false);
  };
  const handleEdit = (item) => {
    setMemoId(item.id);
    const memoArray = [];
    let index = 0;
    for (const element of item.text.matchAll(/^\[(.*)\]\((.*)\)$/gm)) {
      console.log(element);
      if (element.index > index)
      {
        memoArray.push(item.text.substring(index, element.index - 1));
      }
      memoArray.push(element[0]);
      index = element.index + element[0].length + 1;
    }
    if (index < item.text.length)
    {
      memoArray.push(item.text.substring(index, item.text.length));
    }
    setMemo(memoArray);
  };
  const handleDelete = async (item) => {
    toastr.info("テキストメモを削除中...");
    const url = api.host + api.storage_list_api;
    const result = await axios.delete(`${url}/${item.id}`);
    console.log(result);
    await retrieveMemo(1);
    toastr.success("テキストメモを削除しました");
  };
  const handleUpdatedPinned = (item) => {
    let newPinnedMemoHistory, newNormalMemoHistory;
    if (pinnedMemoHistory.indexOf(item) >= 0)
    {
      pinnedMemoHistory.splice(pinnedMemoHistory.indexOf(item), 1);
      newPinnedMemoHistory = pinnedMemoHistory;
      setPinnedMemoHistory(newPinnedMemoHistory);
      normalMemoHistory.push(item);
      newNormalMemoHistory = normalMemoHistory.sortBy("id", true);
      setNormalMemoHistory(newNormalMemoHistory);
    }
    else
    {
      normalMemoHistory.splice(normalMemoHistory.indexOf(item), 1);
      newNormalMemoHistory = normalMemoHistory;
      setNormalMemoHistory(newNormalMemoHistory);
      pinnedMemoHistory.push(item);
      newPinnedMemoHistory = pinnedMemoHistory.sortBy("id", true);
      setPinnedMemoHistory(newPinnedMemoHistory);
    }
    setMemoHistory([...newPinnedMemoHistory, ...newNormalMemoHistory]);
  };

  const retrieveMemo = async (page) => {
    try {
      setIsFetching(true);
      const url = api.host + api.storage_list_api + "/history";
      const params = {
        page: page - (pinnedMaxPage != null ? pinnedMaxPage : 0),
      };
      if (pinnedMaxPage == null) {
        params.hashtags = "!pin:ON";
      }
      const res = await axios.get(url, {
        params: params,
      });

      console.log(res.data.meta.current_page, res.data.meta.last_page);
      if (res.data.meta.current_page >= res.data.meta.last_page) {
        if (pinnedMaxPage == null) {
          setPinnedMaxPage(res.data.meta.last_page);
        } else {
          setHasMore(false);
        }
      }

      let newPinnedMemoHistory, newNormalMemoHistory;
      if (pinnedMaxPage == null)
      {
        newPinnedMemoHistory = [...pinnedMemoHistory, ...res.data.data].unique(x => x.id).sortBy('id', true);
        newNormalMemoHistory = normalMemoHistory;
        setPinnedMemoHistory(newPinnedMemoHistory);
      }
      else
      {
        newPinnedMemoHistory = pinnedMemoHistory;
        newNormalMemoHistory = [...normalMemoHistory, ...res.data.data.filter(x => x.hashtags.indexOf("!pin:ON") < 0)].unique(x => x.id).sortBy('id', true);
        setNormalMemoHistory(newNormalMemoHistory);
      }

      setMemoHistory([...newPinnedMemoHistory, ...newNormalMemoHistory]);
    } catch (e) {
      setHasMore(false);
    } finally {
      setIsFetching(false);
    }
  };

  // const loader = <div style={{ width: "100%"} }><SyncLoader size={8} color={"#2882aa"} /></div>;

  return (
    <>
      <div className={`c-sideMenu ${isWide ? 'is-wide' : ''}`}>
        <div className="c-memoForm__inner c-memoForm__inner--header c-memoForm__inner--has-wrap">
          <div className="c-sideMenu__head">
            <div className="c-sideMenu__head__left">
              <img src="/assets/images/icon_memo_black01.svg" alt=""/>
              <span className="c-memoForm__title c-memoForm__title--header">メモを残す</span>
            </div>
            <div className="c-sideMenu__head__right">
              <div className="c-sideMenu__head__right__search">
                <img src="/assets/images/icon_search01.svg" alt=""/>
              </div>
              <div className={`c-sideMenu__head__right__expand ${isWide ? 'u-dnone' : ''}`} onClick={handleExpand}>
                <img src="/assets/images/icon_expand.svg" alt=""/>
              </div>
              <div className={`c-sideMenu__head__right__collapse ${isWide ? 'u-dib' : ''}`} onClick={handleCollapse}>
                <img src="/assets/images/icon_collapse.svg" alt=""/>
              </div>
            </div>
         </div>

          <div className="c-memoForm__btns">
            <div
                className="c-memoForm__closeBtn js-memoFormClose"
                onClick={props.handleMemoModalClose}
            ></div>
          </div>

          <div className="c-sideMenu__area">
            <SideMemoTextarea memo={memo} onSave={handleMemoSave} onCancel={handleCancel} onChange={handleMemoChange} isEdit={memoId != null} />

            <div className="c-sideMenu__area--header">
              <div className="c-sideMenu__area--header__inner">
                <InfiniteScroll
                    style={{ paddingBottom: "30px" }}
                    loadMore={retrieveMemo}
                    hasMore={!isFetching && hasMore}
                    useWindow={false}>
                  {
                    memoHistory.map((item, index) => {
                      return (
                          <MemoCard key={index} item={item}
                                    onEdit={handleEdit} onDelete={handleDelete} onUpdatedPinned={handleUpdatedPinned}
                                    isEdit={item.id == memoId} />
                      );
                    })
                  }
                </InfiniteScroll>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SideMemo;
