import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Common
import { ApiContext } from '../App';
import toastr from "toastr";
import Sugar from "sugar";
import html2canvas from "html2canvas";

import SideMemo from './SideMemo';
import PopupExecuteAnalysis from "./popup/PopupExecuteAnalysis";

const Header = () => {
	const api = useContext(ApiContext);
	const url = api.host + api.users_api;

	const [me, setMe] = useState({});
	const [memoModalOpen, setMemoModalOpen] = useState(false);

	const handleMemoModalOpen = () => {
		setMemoModalOpen(true);
	};

	const handleMemoModalClose = () => {
		setMemoModalOpen(false);
	};

	const addMarkdown = useRef(null);

	// popup
	const [openname, setModalOpenName] = useState();
	const [flg, setFlg] = useState(true);
	const modalOpen = (modalname) => {
		if (flg) {
			setFlg(false);
		}
		setModalOpenName(modalname);
	};

	const modalClose = () => {
		if (flg) {
			return;
		} else {
			setFlg(true);
		}
		setModalOpenName('');
	};

	// ログインユーザー情報
	useEffect(() => {
		axios
			.get(url)
			.then((result) => {
				setMe(result.data.data);
				api.me = result.data.data;
				// omitted(result.data.data)
				// console.log('result: ', result.data);
			})
			.catch((error) => {
				console.log('error: ', error.response);
			});
	}, [url]);

	const logout = (e) => {
		e.preventDefault();
		if (localStorage.getItem('jfc')) {
			localStorage.clear();
		}
		window.location.href = '/users/auth/logout';
	};

	const screenShot = async (e) => {
		e.preventDefault();
		toastr.info("画面メモを撮影中...");
		const canvas = await html2canvas(document.querySelector("#root"));
		const dataUrl = canvas.toDataURL("image/png");
		const bin = atob(dataUrl.split(',')[1]);
		const buffer = new Uint8Array(bin.length);
		for (let i = 0; i < bin.length; i++)
		{
			buffer[i] = bin.charCodeAt(i);
		}
		const blob = new Blob([buffer.buffer], {type: "image/png"});
		const timeString = Sugar.Date.format(new Date(), "%Y%m%d%H%M%S");
		const fileName = `${timeString}_画面メモ.png`;
		const formData = new FormData();
		formData.append("file", blob, fileName);
		formData.append("file_type_id", 1);
		const url = api.host + api.storage_list_api;
		const result = await axios.post(url, formData);
		console.log(result);
		toastr.success("画面メモを保存しました");

		const markdown = `[${result.data.data.name}](/api/storages/${result.data.data.id})`;
		addMarkdown.current(markdown);
	};
	const handleChangeMemo = (func) => {
		addMarkdown.current = func;
	};

	return (
		<>
			<header className="l-header">
				<div style={{ display: "flex" }}>
					<Link to="/" className="l-header__link">
						<h1 className="l-header__logo">
							<img src="/assets/images/viewpoint_logo.svg" alt="ViewPoint" />
						</h1>
					</Link>
					<div className="c-btn u-ml15">
						<div
							className="c-btn__link c-btn__link--size-s js-popOpen"
							data-pop="pop1"
							onClick={() => { modalOpen('exec-analysis') }}
							style={{ cursor: 'pointer' }}
						>
							＋ 分析を新規追加
						</div>
					</div>
				</div>

				<div
					className={
						openname === 'header'
							? 'l-header__account c-accountMenuBtn is-open'
							: 'l-header__account c-accountMenuBtn'
					}
					onClick={() => modalClose(flg)}
				>
					<div className="c-accountMenuBtn__btn">
						<div className="c-accountMenuBtn__btnInner js-accountMenuBtnOpen">
							<img height="30" width="150" src="/assets/images/logo_sample01.svg" alt="CROSS DISCOVER" />
							<img height="30" width="150" src="/assets/images/circles.png" />
							<a className="u-ml20" onClick={screenShot} style={{cursor: 'pointer'}}>
								<span className="c-roundIcon c-roundIcon--hov-border c-roundIcon--hov-icon">
										<img src="/assets/images/camera.svg" alt="" />
										<img src="/assets/images/camera_blue.svg" alt="" />
										<span className="c-roundIcon__tooltip" data-html2canvas-ignore>画面メモを保存する</span>
								</span>
							</a>
							<input
								type="button"
								className="c-btn__link c-btn__link--light-gray c-btn__link--size-ss u-ml5"
								value="メモを開く"
								onClick={handleMemoModalOpen}
							/>

							<div
								className={
									memoModalOpen
										? 'c-memoForm c-memoForm--header is-open'
										: 'c-memoForm c-memoForm--header'
								}
								style={{ "pointerEvents": memoModalOpen ? undefined : 'none' }}
							>
								<SideMemo handleMemoModalClose={handleMemoModalClose} handleChangeMemo={handleChangeMemo} />
							</div>

							<span
								className="c-accountMenuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon"
								onClick={() => modalOpen('header')}
							>
								<img src="/assets/images/icon_person_black01.svg" alt="" />
								<img src="/assets/images/icon_person_blue01.svg" alt="" />
							</span>
							<div
								className="c-accountMenuBtn__name"
								onClick={() => modalOpen('header')}
							>
								{me.email &&
									me.email.substr(0, me.email.indexOf('@') + 1) + '...'}
							</div>
						</div>
					</div>

					<ul className="c-accountMenuBtn__list">
						{/*<li><Link to="#">XXXXXXXXXXX</Link></li>*/}
						<li>
							<Link to="#" onClick={(e) => logout(e)}>
								ログアウト
							</Link>
						</li>
					</ul>
				</div>
			</header>

			<PopupExecuteAnalysis openname={openname} modalCloseCallback={()=> modalClose()} />
		</>
	);
};

export default Header;
